import React, { useState, useEffect } from "react";
// import Calendar from "react-calendar";
import { useLocation, useNavigate } from "react-router-dom";
// import DatePicker from "sassy-datepicker";
import DatePicker, {
  Calendar,
  DateObject,
  getAllDatesInRange,
} from "react-multi-date-picker";
import { generatePaymentIntent } from "../../API/ApiService";
import Checkout from "../../Component/Checkout";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "../../Redux/Action/loadingAction";
import { toast } from "react-toastify";
import StateDropdown from "../../Component/StateDropdown";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { getSchedule } from '../../API/ApiService'
import SelectBox from "../../Component/select-box"

Modal.setAppElement("#root");

const PlanOne = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [locationState, setLocationState] = useState({
    price: "0",
    radius: "5",
  });
  const [count, setCount] = useState(1);
  const [multiDate, setMultiDate] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [subTotal, setSubTotal] = useState();
  const [clientSecret, setPaymentIntent] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedNoOfDays, setSelectedNoOfDays] = useState(0);
  const [dailyplan, setDailyPlan] = useState()
  const { stateList } = useSelector((state) => state.locationReducer);
  const currentState = stateList.find((s) => s.state_code === selectedState);
  const isDaily = locationState?.duration?.includes("Daily");
  let isLocalDaily = dailyplan?.duration?.includes("Daily");
  let isLocalMonthly = dailyplan?.duration?.includes("Monthly");
  const isState = locationState?.title?.includes("State");
  const isNational = locationState?.title?.includes("National");
  let dates = multiDate.join(",");
  let arrDates = dates === "" ? [] : dates.split(",");
  const [statePrice, setStatePrice] = useState(0);


  useEffect(() => {
    if (locationState?.title === 'Local') {
      setDailyPlan(locationState?.item[0])
    }
  }, [locationState])
  useEffect(() => {
    if (locationState?.title === 'Local') {
      isLocalDaily = dailyplan?.duration?.includes("Daily");
      isLocalMonthly = dailyplan?.duration?.includes("Monthly");
    }
    setMultiDate([])
    setSelectedNoOfDays(0)
    arrDates = []
    setCount(1)

  }, [dailyplan])

  useEffect(() => {
    setLocationState(location.state.item);
  }, [location]);

  useEffect(() => {
    if (currentState) {
      setLocationState((prev) => ({
        ...prev,
        price: currentState.plan_price,
      }));
    }
  }, [currentState]);

  const dec = () => {
    if (count <= 1) {
      return;
    } else {
      setCount(count - 1);
    }
  };
  useEffect(() => {
    if (isDaily) {
      setSelectedNoOfDays(arrDates.length);
    } else if (isLocalDaily) {
      setSelectedNoOfDays(arrDates.length);
    } else if (isLocalMonthly) {

      // setSelectedNoOfDays(arrDates.length);
      if (arrDates.length > 0) {
        setSelectedNoOfDays(30);
      }
    } else {
      if (arrDates.length > 0) {
        setSelectedNoOfDays(30);
      }
    }
  }, [arrDates, isLocalDaily, isLocalMonthly, isDaily]);

  useEffect(() => {
    let total = 0;
    if (isDaily) {
      total = parseFloat(locationState?.price).toFixed(2) * selectedNoOfDays * count;
    } else if(isState) {
      if(selectedNoOfDays) {
      total = parseFloat(statePrice).toFixed(2) * count;
    }
    } else if (isLocalDaily) {
      total = parseFloat(dailyplan?.price).toFixed(2) * selectedNoOfDays * count;
    } else if (isLocalMonthly) {
      if (selectedNoOfDays !== 0) {
        total = parseFloat(dailyplan?.price).toFixed(2) * count;
      }
    } else {
      if (locationState?.price) {
        total = parseFloat(locationState?.price).toFixed(2) * count;
      } else {
        total = 0
      }
    }
    setSubTotal(total);
  }, [selectedNoOfDays, count, locationState, dailyplan, isLocalDaily, isState, statePrice]);

  const [scheduleDate, setScheduleDate] = useState([])
  const [isDateSelected, setIsDateSelected] = useState(false)

  // get schedule
  useEffect(() => {
    dispatch(loadingAction(true));

    let business_id = localStorage.getItem("id");
    if (business_id) {
      getSchedule({ 'business_id': business_id }).then((res) => {
        res?.data?.result?.map(itm => {
          setScheduleDate(arr => [...arr, itm?.start_date])
        })
        dispatch(loadingAction(false));

      }).catch((err) => {
        dispatch(loadingAction(false));
        console.log(err, 'err')
      });
    }
  }, [])

  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile ? "95%" : "500px",
      padding: "3rem",
      paddingTop: "2rem",
      maxHeight: '80vh'
    },
  };

  const getIntent = () => {
    dispatch(loadingAction(true));
    const duration = [];
    let planId;
    let radius;
    if (isLocalDaily || isLocalMonthly) {
      planId = dailyplan?.id
      radius = dailyplan?.radius
      arrDates.map((date) => {
        duration.push({
          start_date: moment(date?.replace("/n", "")).format("YYYY-MM-DD"),
          end_date: moment(date?.replace("/n", "")).format("YYYY-MM-DD"),
        });
      });
    } else {
      planId = locationState?.id
      radius = locationState?.radius

      Array.from({ length: 30 }, (_, index) => {
        duration.push({
          start_date: moment(arrDates[0]?.replace("/n", ""))
            .add(index, "days")
            .format("YYYY-MM-DD"),
          end_date: moment(arrDates[0]?.replace("/n", ""))
            .add(index, "days")
            .format("YYYY-MM-DD"),
        });
      });
    }
    generatePaymentIntent({
      business_id: localStorage.getItem("id"),
      amount: parseInt(subTotal * 100),
      payment_type: isLocalDaily ? "DAILY" : "MONTHLY",
      duration,
      distance: count * parseInt(radius || 0),
      plan_id: planId,
    })
      .then((res) => {
        if (res.data.status === 200) {
          const { paymentIntent } = res.data.result;
          setPaymentIntent(paymentIntent);
          setIsOpen(true);
        }
        if (res.data.status === 500) {
          const token = localStorage.getItem("accessToken");
          token
            ? toast.error("Token Expired, Login Again")
            : toast.info("Please Login To Purchase Plan");
          localStorage.removeItem("accessToken");
          navigate("/login");
        }
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch(loadingAction(false));
      });
  };

  const getContent = (type) => {
    switch (type) {
      case "Premium":
        return (
          <ul>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              <div>
                Local premium ads will be displayed in your selected radius a
                the top of the home page.
              </div>
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Statewide premium ads will be displayed on all customer devices in
              the selected state.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Nationwide premium ads will be displayed on all customer devices
              in the US.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Unlike industry standard we do not restrict or limit exposure
              based on your selected coverage.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "5px" }}
              />
              <div>
                <span className="font-weight-bold">Select</span> - Select Ads
                will be visible on the homescreen, it will be located below the
                New in business category and also visible on the home page.
              </div>
            </li>
          </ul>
        );
      case "Standard":
        return (
          <ul>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Standard Ads will appear below Featured Businesses and Daily
              Deals.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Local standard ads will be displayed in your selected radius.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Statewide standard ads will be displayed on all customer devices
              in the selected state.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Nationwide standard ads will be displayed on all customer devices
              in the US.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Unlike industry standard we do not restrict or limit exposure
              based on your selected coverage.
            </li>
          </ul>
        );
      default:
        return (
          <ul>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Ads for daily deals will appear on the Daily Deals page.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Ads in the Daily Deal section are available for local businesses
              only.
            </li>
            <li className="d-flex align-items-start">
              <img
                src="images/shop.svg"
                style={{ marginRight: "12px", marginTop: "4px" }}
              />
              Ads will be displayed on all customer devices up to a 100 mile
              radius.
            </li>
          </ul>
        );
    }
  };
  const currentDate = new Date();
  return (
    <div id="plan-view">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Checkout Modal"
        shouldCloseOnOverlayClick={false}
        centered
      >
        <Checkout
          amount={parseFloat(subTotal).toFixed(2)}
          clientSecret={clientSecret}
          onRequestClose={() => setIsOpen(false)}
        />
      </Modal>
      <section className="green-heading">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>
                {locationState?.plan_name}-{locationState?.type}
              </h2>
              <p>Bridging the gap between local businesses and customers.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="you-get">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="you-get-img">
                {locationState?.root_type?.toLowerCase().includes("daily") && (
                  <img src="images/ad2.png" className="img-fluid" />
                )}
                {locationState?.root_type?.toLowerCase() === "premium" && (
                  <img
                    src="images/premium2.png"
                    className="img-fluid"
                    style={{ transform: "rotate(15deg)" }}
                  />
                )}
                {locationState?.root_type?.toLowerCase() === "standard" && (
                  <img
                    src="images/standard2.png"
                    className="img-fluid"
                    style={{ transform: "rotate(15deg)" }}
                  />
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="ad-benifits-txt you-get-txt">
                <span>{locationState.root_type} Subscription</span>
                <h4>
                  What you will get with{" "}
                  <font>
                    <img src="images/green-suf.png" className="ben-suf" />
                    {locationState.root_type}
                  </font>{" "}
                  Subscription
                </h4>
                {getContent(locationState.root_type)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="checkout1">
        <div className="container">
          <div className="row align-items-baseline">
            <div className="col-md-8">
              <div className="daily-monthly-tabs">
                {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="daily-plan-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#daily-plan"
                      type="button"
                      role="tab"
                      aria-controls="daily-plan"
                      aria-selected="true"
                    >
                      Daily
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="monthly-plan-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#monthly-plan"
                      type="button"
                      role="tab"
                      aria-controls="monthly-plan"
                      aria-selected="false"
                    >
                      Monthly
                    </button>
                  </li>
                </ul> */}
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="daily-plan"
                    role="tabpanel"
                    aria-labelledby="daily-plan-tab"
                  >
                    <div className="calendar-dates">
                      {!isState && !isNational && (
                        <>
                          <h4 className="set-radius">Select Plan</h4>
                          <div className="border-bottom1"></div>
                          <div>
                            {locationState?.item?.map((val, index) =>
                              <>
                                <input type="radio" id="html" name="fav_language" value="HTML" className="my-2 mx-2" onClick={(e) => setDailyPlan(val)} defaultChecked={index === 0} />
                                <label className="daily-plan" for="html">{val?.duration} (
                                  ${val?.price}
                                  <font>/{val?.duration === 'Daily' ? "day" : "month"}</font>{" "}
                                  {<span> for {val?.radius} Miles </span>}
                                  )</label><br />
                              </>
                            )}
                          </div>
                          <div className="border-bottom1"></div>

                          <h4>Add radius Details ({dailyplan?.duration})</h4>
                          <div className="border-bottom1"></div>
                        </>
                      )}

                      {isState && (
                        <div
                          className="set-radius"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5>Select State</h5>
                          <StateDropdown
                            currentState={currentState}
                            onChange={(e) => setSelectedState(e.target.value)}
                            setStatePrice={setStatePrice}
                          />
                        </div>
                      )}

                      {!isState && !isNational && (
                        <div className="set-radius">
                          <h5>Set Your Radius (Miles)</h5>
                          <div className="number">
                            <button className="minus" onClick={dec}>
                              -
                            </button>
                            {/* <input type="text" value="1" /> */}
                            {count * parseInt(locationState?.radius || 0)}
                            <button
                              className="plus"
                              onClick={() => {
                                const totalRadius =
                                  parseInt(locationState?.radius) * count;
                                if (totalRadius < 50) {
                                  setCount(count + 1);
                                }
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="border-bottom1"></div>
                      <h2>
                        Total days selected <font>{selectedNoOfDays}</font>
                      </h2>
                      <div className="main-calendar">
                        <h3>Select a date you want to run your add</h3>
                        {isLocalDaily ? (
                          <Calendar
                            multiple
                            onlyShowInRangeDates={true}
                            minDate={currentDate.setDate(
                              currentDate.getDate() + 1
                            )}
                            value={multiDate}
                            onChange={setMultiDate}
                            mapDays={({ date }) => {
                              let dateCustomDay = (date.day < 10 ? ('0' + date.day) : date.day)
                              let dateCustomMonth = (date.month.number < 10 ? ('0' + date.month.number) : date.month.number)
                              let dateCustom = date.year + '-' + dateCustomMonth + '-' + dateCustomDay
                              let isDisabled = scheduleDate.includes(dateCustom.toString())

                              if (isDisabled) return {
                                disabled: true,
                                style: { color: "#grey", background: 'grey' },
                              }
                            }}
                          />
                        ) : (
                          <Calendar
                            range
                            onlyShowInRangeDates={true}
                            value={multiDate}
                            minDate={currentDate.setDate(
                              currentDate.getDate() + 1
                            )}
                            onChange={(array) => {
                              setMultiDate([
                                array.join(",\n"),
                                new DateObject(array.join(",\n")).add(
                                  29,
                                  "days"
                                ),
                              ]);
                            }}
                            mapDays={({ date }) => {
                              let dateCustomDay = (date.day < 10 ? ('0' + date.day) : date.day)
                              let dateCustomMonth = (date.month.number < 10 ? ('0' + date.month.number) : date.month.number)
                              let dateCustom = date.year + '-' + dateCustomMonth + '-' + dateCustomDay
                              let isDisabled = scheduleDate.includes(dateCustom.toString())

                              if (isDisabled) return {
                                disabled: true,
                                style: { color: "#grey", background: 'grey' },
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="monthly-plan"
                    role="tabpanel"
                    aria-labelledby="monthly-plan-tab"
                  >
                    <div className="calendar-dates">
                      <h4>Add radius Details (Daily)</h4>
                      <div className="border-bottom1"></div>
                      <div className="set-radius">
                        <h5>Set Your Radius (Miles)</h5>
                        <div className="number">
                          <span className="minus" onClick={dec}>
                            -
                          </span>
                          {count}
                          <span
                            className="plus"
                            onClick={() => setCount(count + 10)}
                          >
                            +
                          </span>
                        </div>
                      </div>
                      <div className="border-bottom1"></div>
                      <h3>Select a date you want to run your add</h3>
                      <h2>
                        Total days selected <font>02</font>
                      </h2>
                      <div className="main-calendar">
                        {" "}
                        <Calendar
                          range
                          // calendarPosition="top-left"
                          // fixMainPosition
                          onlyShowInRangeDates={true}
                          // value={dates}
                          // minDate={new DateObject().toFirstOfMonth()}
                          // maxDate={new DateObject().toLastOfMonth()}
                          onChange={(array) => {
                            setDatess(array);
                            setAllDates(getAllDatesInRange(array.join(",\n")));
                          }}
                          // onChange={setAllDates}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="pln-summry">
                <h4>Plan Summary</h4>
                <div className="border-bottom1"></div>
                {isLocalDaily &&
                <h5> Daily ({selectedNoOfDays} {selectedNoOfDays === 1 ? 'day' : 'days'})</h5>
                }
                {isLocalMonthly &&
                <h5> Monthly ({selectedNoOfDays} days)</h5>
                }
                {(isState || isNational) &&
                <h5> Monthly ({selectedNoOfDays} days)</h5>
                }
                {/* {!!subTotal && */}
                <span>${parseFloat(subTotal).toFixed(2)}</span>
                {/* } */}
                <div className="border-bottom1"></div>
                <h3>Order Total</h3>
                {/* {!!subTotal && */}
                <p>${parseFloat(subTotal).toFixed(2)}</p>
                {/* } */}
                <div className="border-bottom1"></div>

                <button
                  disabled={
                    isState
                      ? selectedNoOfDays === 0 || selectedState === ""
                      : selectedNoOfDays === 0
                  }
                  className="pln-next-btn btn btn-success"
                  onClick={() => getIntent()}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PlanOne;
