import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getPlanList } from "../../Redux/Action/PlanAction/planAction";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import GridCardWrapper from "../../Component/GridCardWrapper";
import "./adPurchase.css";
import styled from "styled-components";

const AdPurchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [planList, setPlans] = useState({});

  const [planType, setPlanType] = useState();
  const [subPlanList, setSubTypeList] = useState([]);
  const [subPlan, setSubType] = useState();
  const [scrolled, setScrolled] = useState(100);
  const [scrolledX, setScrolledX] = useState(52);
  const [blink, setBlink] = useState(false);

  const [renderPlanList, setRenderPlans] = useState([]);

  useEffect(async () => {
    const response = await dispatch(getPlanList());
    if (response?.type === "GET_PLAN_LIST_SUCCESS") {
      const plans = {};
      response?.payload.forEach((p) => {
        let key = Object.keys(p)[0];
        let obj = {
          planList: p[key].planList,
          subPlans: {},
        };
        p[key]?.subPlans?.forEach((sub) => {
          let subKey = Object.keys(sub)[0];
          obj.subPlans[subKey] = sub[subKey];
        });
        plans[key] = obj;
      });
      setPlans(plans);
    }
  }, []);

  useEffect(() => {
    const planKeys = Object.keys(planList);
    if (planKeys.length > 0) {
      setPlanType(planKeys[0]);
    }
  }, [planList]);

  useEffect(() => {
    if (planType) {
      const subPlanKeys = Object.keys(planList[planType].subPlans);
      if (subPlanKeys.length > 0) {
        setSubTypeList(subPlanKeys);
        setSubType(subPlanKeys[0]);
      } else {
        setSubTypeList([]);
        setSubType();
      }
    }
  }, [planType]);

  useEffect(() => {
    let newArr = [];
    let finalArr = []
    let objLocal = {}
    objLocal.title = 'Local'
    objLocal.plan_name = 'Local'
    objLocal.description = 'Unlike industry standards, ads on Brigiz will not be limited by clicks or views. All Local Ads will be visible to all users in the selected radius.'
    objLocal.item = []
    objLocal.root_type = ''
    objLocal.type = ''
    objLocal.radius = ''


    if (Object.keys(planList).length > 0 && planType) {
      if (planList[planType].planList.length > 0) {
        // setRenderPlans(planList[planType].planList);
        // if (planList[planType].subPlans[planType]) {
        // planList[planType].subPlans[subPlan].
        planList[planType]?.planList?.map((val, idx) => {
          if (val?.title === 'Local') {
            objLocal.item.push(val)
            objLocal.root_type = val?.root_type
            objLocal.type = val?.type
            objLocal.radius = val?.radius
          } else {
            newArr.push(val)
          }
        })
        if (JSON.stringify(objLocal) !== '{}') {
          finalArr.push(objLocal)
        }
        if (newArr.length > 0) {
          newArr.map((val) => {
            finalArr.push(val)
          })
          setRenderPlans(finalArr);
        }
      } else {
        if (planList[planType].subPlans[subPlan]) {
          planList[planType].subPlans[subPlan].map((val, idx) => {
            if (val?.title === 'Local') {
              objLocal.item.push(val)
              objLocal.root_type = val?.root_type
              objLocal.type = val?.type
              objLocal.radius = val?.radius

            } else { 
              newArr.push(val)
            }
          })
        }
        if (JSON.stringify(objLocal) !== '{}') {
          finalArr.push(objLocal)
        }
        if (newArr.length > 0) {
          newArr.map((val) => {
            finalArr.push(val)
          })
        }
        subPlan && setRenderPlans(finalArr);
        // subPlan && setRenderPlans(planList[planType].subPlans[subPlan]);  
      }
    }
  }, [subPlan]);

  const renderPlans = (gridProps) => {
    if (!gridProps) return;
    const {
      item, 
      id,
      plan_name,
      description,
      price,
      duration,
      type,
      title,
      radius,
      area_type,
    } = gridProps?.data; //destructuring

    const isDaily = duration?.toLowerCase() === "daily";
    const isLocal = title.includes("Local");
    return (

      <div
        style={{
          width: gridProps?.width,
          marginBottom: "10px",
          height: gridProps?.height,
          marginLeft: gridProps?.marginLeft,
        }}
      >
        <div className="premium-one-box">
          <h4>{title}</h4>
          <p style={{ height: "150px" }}>{description}</p>
            {/* <h5>
              {title === 'Local'
                ? <>{"  "}</>
                : <>${price} 
                  <font>/{isLocal ? "day" : "month"}</font>{" "}</>
              } */}
              
              {/* {isLocal && <span> for {radius} Miles </span>} */}
            {/* </h5> */}
          <Link to="/PlanOne" state={{ item: gridProps?.data }}>
            <button className="ad-choose-plan">Choose Plan</button>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* <div
       style={{ display: "none" }}
      >
        <PlanOne price="90Rs" />
      </div> */}
      <section className="green-heading">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <h2>Purchase Ad Space</h2>
              <p>Bridging the gap between local businesses and customers.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="ad-benifits">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-8 adPurchaseBlock">
              <div className="ad-benifits-heading">
                <h3>
                  No more pay per <font>clicks</font> or{" "}
                  <font>impressions</font>
                </h3>
                <p>
                  Bridges was created to seamlessly market your business to
                  everyone. Let us help you grow your business today!
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 adPurchaseBlock">
              {scrolled !== "basic" && (
                <div className="ad_plans_viewWrapper">
                  <DailyDealImg>
                    <Wrapper scrolled={scrolled} scrolledX={scrolledX}>
                      <img
                        src="images/ad-phone.png"
                        width={320}
                        height={620}
                        style={{
                          position: "absolute",
                          top: "-34px",
                          left: "-30px",
                          transition: "all .8s ",
                        }}
                      />
                    </Wrapper>
                  </DailyDealImg>
                  {scrolled === 180 && (
                    <div
                      style={{
                        position: "relative",
                        opacity: scrolledX === 304 && "0",
                      }}
                    >
                      <span
                        className="blink"
                        style={{ opacity: blink ? "1" : "0" }}
                      ></span>
                      <img
                        src="images/cursor.png"
                        style={{
                          opacity: blink ? "1" : "0",
                        }}
                        className="mouseCursor"
                      />
                    </div>
                  )}

                  <img
                    src="images/inde.svg"
                    className="indecatorCircle1"
                    style={{
                      top: scrolled === 100 ? "105px" : "55px",
                      // transition: "all 2s",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  />
                  <img
                    src="images/inde.svg"
                    className="indecatorCircle1"
                    style={{
                      top: scrolledX === 304 && "80px",
                      opacity: scrolledX === 304 ? "1" : "0",
                    }}
                  />
                  <img
                    src="images/arrow.svg"
                    className="arrowIndecate1"
                    style={{
                      top: scrolled === 100 ? "150px" : "80px",
                      transition: "all 2s",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  />
                  <img
                    src="images/arrow.svg"
                    className="arrowIndecate1"
                    style={{
                      top: scrolledX === 304 && "90px",
                      transition: "all 2s",
                      opacity: scrolledX === 304 ? "1" : "0",
                    }}
                  />
                  <span
                    className="ad_plansPremium"
                    style={{
                      fontSize: scrolledX === 304 ? "20px" : "0",
                      transition: "all 1.5s",
                      top: scrolledX === 304 && "165px",
                      opacity: scrolledX === 304 ? "1" : "0",
                    }}
                  >
                    Daily Deals
                  </span>
                  <span
                    className="ad_plansPremium"
                    style={{
                      fontSize: scrolled === 100 ? "20px" : "0",
                      transition: "all 1.5s",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  >
                    Premium
                  </span>
                  <span
                    className="ad_plansPremium"
                    style={{
                      fontSize: scrolled === 180 ? "20px" : "0",
                      transition: "all 1.5s",
                      top: scrolled === 180 && "150px",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  >
                    Standard 1
                  </span>

                  <img
                    src="images/inde.svg"
                    className="indecatorCircle2"
                    style={{
                      bottom: scrolled === 100 ? "-20px" : "84px",
                      transition: "all 2s",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  />
                  <img
                    src="images/arrow.svg"
                    className="arrowIndecate2"
                    style={{
                      transition: "all 2s",
                      bottom: scrolled === 100 ? "5px" : "90px",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  />
                  <span
                    className="ad_plansSelect"
                    style={{
                      fontSize: scrolled === 100 ? "20px" : "0",
                      transition: "all 1.5s",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  >
                    Select
                  </span>
                  <span
                    className="ad_plansStandard"
                    style={{
                      fontSize: scrolled === 180 ? "20px" : "0",
                      transition: "all 1.5s",
                      bottom: scrolled === 180 && "55px",
                      opacity: scrolledX === 304 ? "0" : "1",
                    }}
                  >
                    Standard 2
                  </span>
                </div>
              )}
            </div>
            <div className="col-md-6 adPurchaseBlock">
              <div className="ad-benifits-txt">
                <h2 className="ad_plansLabel">
                  Choose options below to view where
                  <label className="ad_planSpan">
                    your ad
                    <img
                      src="images/green-suf.png"
                      className="ad_plansLabelCircle"
                    />
                  </label>{" "}
                  &nbsp;will appear
                </h2>
                <div className="ad-benifits-txt-tabs">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active "
                        id="pre_txt-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#pre_txt"
                        type="button"
                        role="tab"
                        aria-controls="pre_txt"
                        aria-selected="true"
                        onClick={() => {
                          setScrolledX(52);
                          setTimeout(() => {
                            setScrolled(100);
                          }, 600);
                        }}
                      >
                        Premium
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="std_txt-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#std_txt"
                        type="button"
                        role="tab"
                        aria-controls="std_txt"
                        aria-selected="false"
                        onClick={() => {
                          setTimeout(() => {
                            setScrolled(180);
                          }, 600);
                          setScrolledX(52);
                        }}
                      >
                        Standard
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="basic_txt-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#basic_txt"
                        type="button"
                        role="tab"
                        aria-controls="basic_txt"
                        aria-selected="false"
                        onClick={() => {
                          setBlink(true);
                          setTimeout(() => {
                            setScrolledX(304);
                            setBlink(false);
                          }, 800);
                        }}
                      >
                        Daily Deals
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pre_txt"
                      role="tabpanel"
                      aria-labelledby="pre_txt-tab"
                    >
                      <ul>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          <div>
                            Local premium ads will be displayed in your selected
                            radius a the top of the home page.
                          </div>
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Statewide premium ads will be displayed on all
                          customer devices in the selected state.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Nationwide premium ads will be displayed on all
                          customer devices in the US.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Unlike industry standard we do not restrict or limit
                          exposure based on your selected coverage.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "5px" }}
                          />
                          <div>
                            <span className="font-weight-bold">Select</span> -
                            Select Ads will be visible on the homescreen, it
                            will be located below the New in business category
                            and also visible on the home page.
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div
                      className="tab-pane fade show"
                      id="std_txt"
                      role="tabpanel"
                      aria-labelledby="std_txt-tab"
                    >
                      <ul>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Standard Ads will appear below Featured Businesses and
                          Daily Deals.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Local standard ads will be displayed in your selected
                          radius.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Statewide standard ads will be displayed on all
                          customer devices in the selected state.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Nationwide standard ads will be displayed on all
                          customer devices in the US.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Unlike industry standard we do not restrict or limit
                          exposure based on your selected coverage.
                        </li>
                      </ul>
                    </div>

                    <div
                      className="tab-pane fade show"
                      id="basic_txt"
                      role="tabpanel"
                      aria-labelledby="basic_txt-tab"
                    >
                      <ul>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Ads for daily deals will appear on the Daily Deals
                          page.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Ads in the Daily Deal section are available for local
                          businesses only.
                        </li>
                        <li className="d-flex align-items-start">
                          <img
                            src="images/shop.svg"
                            style={{ marginRight: "12px", marginTop: "4px" }}
                          />
                          Ads will be displayed on all customer devices up to a
                          100 mile radius.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="add-business-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="business-top-heading">
                <h3>Ad Purchase Made Simple</h3>
                <p>Select your plan below!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cost-plans">
          <ul className="nav nav-tabs" id="myTab1" role="tablist">
            {Object.keys(planList).map((p, i) => {
              return (
                <li
                  key={i}
                  className="nav-item adpurchage_btn_wrap"
                  role="presentation"
                >
                  <button
                    className={` nav-link   ${planType === p && "active adpurchage_btn_active"
                      }`}
                    id="premium-tab"
                    type="button"
                    onClick={() => setPlanType(p)}
                  >
                    {p}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="add-business-top-tabs">
          <div className="container">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="premium"
                role="tabpanel"
                aria-labelledby="premium-tab"
              >
                {subPlanList?.length > 0 && (
                  <ul className="nav nav-tabs" id="myTab2" role="tablist">
                    {subPlanList?.map((sub, i) => (
                      <li className="nav-item" role="presentation" key={i}>
                        <button
                          className={`nav-link  ${sub === subPlan &&
                            "active adpurchage_standard_active"
                            }`}
                          id={`${sub}`}
                          type="button"
                          onClick={() => setSubType(sub)}
                        >
                          {sub}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="prem1"
                    role="tabpanel"
                    aria-labelledby="prem1-tab"
                  >
                    <GridCardWrapper
                      data={renderPlanList}
                      blockHeight={336}
                      blockWidth={336}
                    // handleViewDetail={businessPage}
                    >
                      {(props) => renderPlans(props)}
                    </GridCardWrapper>
                    <div className="row prem1-boxes">{renderPlans()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="for-border" />
        </div>
      </section>
    </>
  );
};

export default AdPurchase;

const Wrapper = styled.div`
  background-image: url("../images/ad1.png");
  background-repeat: no-repeat;
  background-size: calc(98% - 55px);
  width: 304px;
  min-height: 545px;
  ${(props) => `
  background-position: calc(100% - ${props.scrolledX}px);
  background-position-y: calc(${props.scrolled}% - -904px);
  transition: all 1s;
  `}
`;

const DailyDealImg = styled.div`
  background-image: url("../images/daily_deal.png");
  background-repeat: no-repeat;
  width: 304px;
  min-height: 545px;
  background-size: calc(100% - 40px);
  background-position: calc(100% - 40px);
`;
